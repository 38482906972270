import React from "react";
import AsyncApiComponent from "@kyma-project/asyncapi-react";

import { specMock } from "../looksee_chat";
import "@kyma-project/asyncapi-react/lib/styles/fiori.css";
import "./../style.css";

const AsyncAPI = () => {
	return(
		<AsyncApiComponent schema={specMock} />
	)
}

export default AsyncAPI;