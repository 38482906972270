import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {Button, Alert, Form, Row, Col} from 'react-bootstrap'
import './../../login.css';
import  Credential from "../../Credential.json"
import Cookies from "js-cookie";

async function loginUser(credentials) {
 return fetch('http://3.221.25.157:8070/login', {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}

const myStyle = {
	paddingTop: "50px",
	color: "black",
}

export default function Login({ setToken, setUser }) {

	const [username, setUserName] = useState();
	const [password, setPassword] = useState();
	const [alert, setAlert] = useState({
		show: false,
		message: '',
		variant: 'success'
	});

	function showAlert(message, variant="success", second=3000) {
		setAlert({
			show: true,
			message,
			variant
		});

		setTimeout(() => {
			setAlert({
				show: false,
				message: '',
				variant: 'success'
			});
		}, second)
	}

  	const handleSubmit = async e => {
		e.preventDefault();
		Cookies.remove('role');
		//   console.log(Credential);

		const checkUser = Credential.users.filter(user => user.user_name === username);
		console.log(checkUser)
		if(!checkUser.length) {
			console.log("NO user found")
			showAlert("Please enter Valid usernam and password!", "danger");
			return false;
		}
		
		const [ { user_name, user_password, role} ] = checkUser;
		
		if(username === user_name && password === user_password) {
			Cookies.set("role", role);
			const token = await loginUser({
				username,
				password
			});
			console.log(`Matching username and password`, role);
			setToken(token);
			setUser({username});
;
			console.log(`cookies role: ${ Cookies.get("role")}`);
			return true;
			
		} else {
			showAlert("Please enter Valid usernam and password!", "danger");
			return false;
		}
  	}

	return(
		<div className="d-flex bd-highlight" style={myStyle}>
			<div className="p-2 flex-fill bd-highlight"></div>
			<div className="p-2 flex-fill bd-highlight border border-default">
				
				<Form onSubmit={handleSubmit}>
					
					<Form.Group as={Row}>
						<Form.Label column sm="3"> </Form.Label>
						<Col sm="6">
							<h1 style={{"textAlign": "center"}}>Log In</h1>
						</Col>
					</Form.Group>
					<Form.Group as={Row} controlId="formPlaintextEmail">
						<Form.Label column sm="3">  </Form.Label>
						<Col sm="6">
							<Form.Control type="text"  placeholder="Username" onChange={e => setUserName(e.target.value)} />
						</Col>
					</Form.Group>

					<Form.Group as={Row} controlId="formPlaintextPassword">
						<Form.Label column sm="3">  </Form.Label>
						<Col sm="6">
							<Form.Control type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
						</Col>
					</Form.Group>
					<Form.Group as={Row}>
						<Form.Label column sm="3"> </Form.Label>
						<Col sm="6">
							<Button variant="outline-secondary" type="submit" block>Submit</Button>
						</Col>
					</Form.Group>
				</Form>
				{alert.show && <Alert variant={alert.variant} > {alert.message} </Alert>}
			</div>
			<div className="p-2 flex-fill bd-highlight"></div>
		</div>
	)
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
  setUser: PropTypes.string.isRequired
};