export const specMock = `
asyncapi: 2.0.0
info:
  title: Looksee Chatting Service
  version: 1.0.0
  description: This service only for chatting application. Here channels name is presence-looksee-chat-{looksee_id}
channels:
  user_typing:
    subscribe:
      message:
        "$ref": "#/components/messages/userTyping"
  message_sent:
    subscribe:
      message:
        "$ref": "#/components/messages/messageSent"
  counter_offer:
    subscribe:
      message:
        "$ref": "#/components/messages/counterOffer"
components:
  messages:
    userTyping:
      payload:
        type: object
        properties:
          sender_id:
            type: string
            description: 5eec7fc8bf1eaa7b83a0baa6
    messageSent:
      payload:
        type: object
        properties:
          content_type:
            type: string
            description: text/offer
          seen_by:
            type: array
            description: all users who already seen the message
          _id:
            type: string
            description: text/offer
          looksee_id:
            type: string
            description: ''
          offer_type:
            type: array
            description: default/cash
          offer_id:
            type: string
            description: '1'
          counter_offer_status:
            type: string
            description: delivered
          mobile_number:
            type: string
            description: sender mobile number
          message_sender_name:
            type: string
            description: sender name
          created_timestamp:
            type: number
            description: text/offer
    counterOffer:
      payload:
        type: object
        properties:
          sender_id:
            type: string
            description: text/offer
          looksee_id:
            type: string
            description: 'Looksee ID'
          offer_type:
            type: string
            description: 'Offer type'
            example: default/cash
          offer_id:
            type: string
            description: '1'
          counter_offer_status:
            type: string
            description: delivered
          message_sender_name:
            type: string
            description: sender mobile number
          created_timestamp:
            type: number
            description: text/offer
`;
