import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import SwaggerUI from "swagger-ui-react"
import Cookies from "js-cookie";
import AsyncAPI from "./async_api_for_looksee"
import "swagger-ui-react/swagger-ui.css"
import Nav from "./Nav";

const SwaggerUIF = () => {
  return (
    <SwaggerUI url="http://3.221.25.157:8070/looksee_api_doc" />
  )
};

const LookseeSoloSwaggerUIF = () => {
  return (
    <SwaggerUI url="http://3.221.25.157:8070/looksee_solo_api_doc" />
  )
};
const logOut = () => {
  sessionStorage.removeItem('user_info');
  sessionStorage.removeItem('token');
  <Redirect to="/" />

  return true;
}
  
const NoMatch = () => {
    return (
      <div>
      <h1 style={{textAlign: "center", padding: "50px" }}>Page Not Found!</h1>
    </div>
  )
}

const Menu =  () => {
  const role = Cookies.get("role");
  console.log(`Cookies data : ${Cookies.get("role")}`);
  return (
    <Router>
      <Nav />
      <Switch>
        <Route path="/" exact component={SwaggerUIF} />
        <Route path="/async_events_for_looksee_solo" exact component={LookseeSoloSwaggerUIF} />
        <Route path="/async_events_for_looksee" exact component={AsyncAPI} />
        <Route path="/logout" component= {logOut} />
        
        <Route component={NoMatch}/>
      </Switch>
    </Router>
  );
};

export default Menu;