import { useState } from 'react';

export default function useUser() {
  const getUser = () => {
    const userString = sessionStorage.getItem('user_info');
    const userName = JSON.parse(userString);
    return userName?.username;
    
  };
  
  const [user, setUser] = useState(getUser());
  
  const saveUser = userName => {
    sessionStorage.setItem('user_info', JSON.stringify(userName));
    setUser(userName.username);
  };

  return {
	  setUser: saveUser,
	  user
  }
}