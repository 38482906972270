import "./index.css";
import "swagger-ui-react/swagger-ui.css"
import Login from './componets/login/Login'
import useToken from "./componets/login/userToken"
import userInfo from "./componets/login/userInfo"
import Manu from "./componets/Manu";

const App = () => {
  const { user, setUser} =  userInfo();
  const { token, setToken } = useToken();
  console.log(user)

  if(!token) {
    return <Login setToken={setToken} setUser= {setUser}/>
  }

  return (
    <div>
      <Manu />
    </div>
  );
}

export default App;